<template>
  <section
    v-if="Object.keys(pricing).length"
    id="pricing-plan"
  >
    <b-overlay
      :show="show"
      rounded="sm"
    >
      <b-row>
        <b-col
          cols="12"
          class="mb-2"
        >
          <div class="text-center">
            <h1 class="mt-5">
              Choisis ton offre
            </h1>
            <p class="mb-2 pb-75">
              Tu souhaites plus d'information ? L'équipe commerciale est joignable au +33 1 76 50 80 70.
            </p>
            <p style="color:#e05959;" class="mb-2">
              Toutes nos formules sont SANS ENGAGEMENT. Paiement en début de mois et sans tacite reconduction le premier mois.
            </p>
          </div>
          <!--/ title text and switch button -->

          <!-- pricing plan cards -->
          <b-row class="pricing-card">
            <b-col
              offset-sm-2
              sm="10"
              md="12"
              offset-lg="2"
              lg="10"
              class="mx-auto"
            >
              <b-row>

                <b-col md="3" sm="12">
                  <b-card
                    :class="{ 'popular': false }"
                    align="center"
                  >
                    <!-- img -->
                    <b-img
                      v-if="pricing.standardPlan.img"
                      :src="pricing.standardPlan.img"
                      class="mb-1"
                      alt="svg img"
                    />
                    <!--/ img -->
                    <h3>1 levier</h3>
                    <b-card-text>Choix du levier : </b-card-text>
                    <div style="height:150px;">
                      <v-select
                        v-model="leviers1"
                        multiple
                        v-on:input="limiter1"
                        :options="leviersListe"
                        label="text"
                      />
                    </div>

                    <!-- annual plan -->
                    <div class="annual-plan">
                      <div class="plan-price mt-2">
                        <span class="pricing-basic-value font-weight-bolder text-primary">590</span>
                        <inf class="font-medium-1 font-weight-bold text-primary">
                          € HT
                        </inf>
                        <br/>
                        <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/ mois</sub>
                      </div>
                    </div>

                    <b-button
                      @click="picked(0)"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      block
                      class="mt-2"
                      variant="outline-primary"
                    >
                      Choisir
                    </b-button>

                  </b-card>
                </b-col>
                <b-col md="3" sm="12">
                  <b-card
                    :class="{ 'popular': true }"
                    align="center"
                  >
                    <div
                      style="position:absolute;"
                      class="pricing-badge text-right"
                    >
                      <b-badge
                        variant="primary"
                        pill
                      >
                        Conseillé
                      </b-badge>
                    </div>
                    <!-- img -->
                    <b-img
                      v-if="pricing.standardPlan.img"
                      :src="pricing.standardPlan.img"
                      class="mb-1"
                      alt="svg img"
                    />
                    <!--/ img -->
                    <h3>2 leviers</h3>
                    <b-card-text>Choix des leviers : </b-card-text>
                    <div style="height:150px;">
                      <v-select
                        v-model="leviers2"
                        multiple
                        v-on:input="limiter2"
                        :options="leviersListe"
                        label="text"
                      />
                    </div>

                    <!-- annual plan -->
                    <div class="annual-plan">
                      <div class="plan-price mt-2">
                        <span class="pricing-basic-value font-weight-bolder text-primary">990</span>
                        <inf class="font-medium-1 font-weight-bold text-primary">
                          € HT
                        </inf>
                        <br/>
                        <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/ mois</sub>
                        <br/>
                        <span class="mt-2" style="color:#59ca59;font-size:0.8rem">Tu économises <strong>190€</strong> chaque mois</span>
                      </div>
                    </div>

                    <b-button
                      @click="picked(1)"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      block
                      class="mt-2"
                      variant="primary"
                    >
                      Choisir
                    </b-button>

                  </b-card>
                </b-col>
                <b-col md="3" sm="12">
                  <b-card
                    :class="{ 'popular': false }"
                    align="center"
                  >
                    <!-- img -->
                    <b-img
                      v-if="pricing.standardPlan.img"
                      :src="pricing.standardPlan.img"
                      class="mb-1"
                      alt="svg img"
                    />
                    <!--/ img -->
                    <h3>3 leviers</h3>
                    <b-card-text>Choix des leviers : </b-card-text>
                    <div style="height:150px;">
                      <v-select
                        v-model="leviers3"
                        multiple
                        v-on:input="limiter3"
                        :options="leviersListe"
                        label="text"
                      />
                    </div>

                    <!-- annual plan -->
                    <div class="annual-plan">
                      <div class="plan-price mt-2">
                        <span class="pricing-basic-value font-weight-bolder text-primary">1290</span>
                        <inf class="font-medium-1 font-weight-bold text-primary">
                          € HT
                        </inf>
                        <br/>
                        <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/ mois</sub>
                        <br/>
                        <span class="mt-2" style="color:#59ca59;font-size:0.8rem">Tu économises <strong>480€</strong> chaque mois</span>
                      </div>
                    </div>

                    <b-button
                      @click="picked(2)"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      block
                      class="mt-2"
                      variant="outline-primary"
                    >
                      Choisir
                    </b-button>

                  </b-card>
                </b-col>
                <b-col md="3" sm="12">
                  <b-card
                    :class="{ 'popular': false }"
                    align="center"
                  >
                    <!-- img -->
                    <b-img
                      v-if="pricing.standardPlan.img"
                      :src="pricing.standardPlan.img"
                      class="mb-1"
                      alt="svg img"
                    />
                    <!--/ img -->
                    <h3>4 leviers</h3>
                    <b-card-text>Choix des leviers : </b-card-text>
                    <div style="height:150px;">
                      <v-select
                        v-model="leviers4"
                        multiple
                        v-on:input="limiter4"
                        :options="leviersListe"
                        label="text"
                      />
                    </div>

                    <!-- annual plan -->
                    <div class="annual-plan">
                      <div class="plan-price mt-2">
                        <span class="pricing-basic-value font-weight-bolder text-primary">1490</span>
                        <inf class="font-medium-1 font-weight-bold text-primary">
                          € HT
                        </inf>
                        <br/>
                        <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/ mois</sub>
                        <br/>
                        <span class="mt-2" style="color:#59ca59;font-size:0.8rem">Tu économises de <strong>870€</strong> chaque mois</span>
                      </div>
                    </div>

                    <b-button
                      @click="picked(3)"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      block
                      class="mt-2"
                      variant="outline-primary"
                    >
                      Choisir
                    </b-button>

                  </b-card>
                </b-col>
                <b-col xl="12" lg="12" md="12" sm="12">
                  <b-card
                    :class="{ 'popular': true }"
                    align="center"
                  >
                    <!-- plan benefit -->
                    <b-list-group class="list-group-circle text-left">
                      <b-list-group-item>
                        Publicités illimitées
                      </b-list-group-item>
                      <b-list-group-item>
                        Tous les formats publicitaires
                      </b-list-group-item>
                      <b-list-group-item>
                        Accompagnement total par un chargé de compte dédié : Paramétrage - Lancement - Optimisations
                      </b-list-group-item>
                      <b-list-group-item>
                        Veille Quotidienne
                      </b-list-group-item>
                      <b-list-group-item>
                      Tests d'audiences et de formats (textes / visuels / vidéos)
                      </b-list-group-item>
                      <b-list-group-item>
                        Stratégie de ciblage : Targeting - Retargeting - Lookalike
                      </b-list-group-item>
                      <b-list-group-item>
                        Dashboard en temps réel / Bilan hebdomadaire vidéo / Bilan mensuel en visio
                      </b-list-group-item>
                      <b-list-group-item>
                        Support 5/7J
                      </b-list-group-item>
                    </b-list-group>
                    <!--/ plan benefit -->

                  </b-card>

                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <!--/ pricing plan cards -->

          <!-- pricing free trial -->
          <div class="pricing-free-trial">
            <b-row>
              <b-col
                lg="10"
                offset-lg="3"
                class="mx-auto"
              >
                <div class="pricing-trial-content d-flex justify-content-between">
                  <div class="text-center text-md-left mt-3">
                    <h3 class="text-primary">
                      Toujours pas convaincu ?
                    </h3>
                    <h5>
                      Laisse-nous te convaincre par le biais d'une démonstration de nos techniques / outils.
                    </h5>
                    <a
                      href="https://www.oscar-black.com/contact"
                      target="_blank"
                    >
                      <b-button

                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        class="mt-2 mt-lg-3"
                      >
                        Prendre rendez-vous
                      </b-button>
                    </a>
                  </div>

                  <!-- images -->
                  <b-img
                    fluid
                    :src="require('@/assets/images/illustration/pricing-Illustration.svg')"
                    class="pricing-trial-img"
                    alt="svg img"
                  />
                  <!--/ images -->
                </div>
              </b-col>
            </b-row>
          </div>
          <!--/ pricing free trial -->

          <!-- pricing faq -->
          <div class="pricing-faq">
            <h3 class="text-center">
              FAQ
            </h3>
            <p class="text-center">
              Laisse-nous t'aider avec les questions les plus posées.
            </p>
            <b-row class="py-2">
              <b-col
                lg="10"
                offset-lg="2"
                class="mx-auto"
              >
                <app-collapse
                  accordion
                  type="margin"
                >

                  <app-collapse-item
                    v-for="(data,index) in pricing.qandA"
                    :key="index"
                    :title="data.question"
                    class="mt-2"
                  >
                    {{ data.ans }}
                  </app-collapse-item>
                </app-collapse>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>

    </b-overlay>
  </section>
</template>

<script>
import Vue from 'vue'
import { StripePlugin } from '@vue-stripe/vue-stripe'
/* eslint-disable vue/html-indent */
/* eslint-disable vue/no-parsing-error */
import axios from '@axios'
import {
  BFormCheckbox, BRow, BCol, BCard, BImg, BCardText, BListGroup, BListGroupItem, BButton, BBadge, BFormGroup, BFormInput, BModal, VBModal, BOverlay,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import Ripple from 'vue-ripple-directive'
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { codeIconInfo } from './code'

const options = {
  pk: 'pk_live_2COtDrKBGn96qOy4qL7nd3q7007TTEPhm1',
}

Vue.use(StripePlugin, options)

export default {
  components: {
    BFormCheckbox,
    BButton,
    BCardText,
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    BCard,
    BBadge,
    BImg,
    AppCollapseItem,
    AppCollapse,
    BFormInput,
    BFormGroup,
    BModal,
    BOverlay,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    vSelect,
    FormWizard,
    TabContent,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  data() {
    this.publishableKey = 'pk_live_2COtDrKBGn96qOy4qL7nd3q7007TTEPhm1'
    return {
      leviersListe: ['Facebook Ads', 'Google Ads', 'TikTok Ads', 'Linkedin Ads', 'Pinterest Ads'],
      leviers1: ['Facebook Ads'],
      leviers2: ['Facebook Ads', 'Google Ads'],
      leviers3: ['Facebook Ads', 'Google Ads', 'TikTok Ads'],
      leviers4: ['Facebook Ads', 'Google Ads', 'TikTok Ads', 'Linkedin Ads'],
      disable: false,
      amount_off: 0,
      percent_off: 0,
      show: false,
      entreprise: '',
      ville: '',
      postalcode: '',
      adresse: '',
      promo: '',
      three_d_secure: false,
      url3Dsecure: '',
      token: null,
      cardNumber: null,
      cardExpiry: null,
      cardCvc: null,
      customerId: '',
      priceId: '',
      username: '',
      loading: false,
      lineItems: [
        {
          price: 'price_1JMx4RE0wlNdvrJgLaz14v1R',
          quantity: 1,
        },
        {
          price: 'price_1JMx6jE0wlNdvrJgezH8kXoS',
          quantity: 1,
        },
      ],
      chose: 0,
      status: 'monthly',
      monthlyPlanShow: true,
      pricing: {},
      codeIconInfo,
      selectedCountry: { text: 'France', value: 'FR' },
      countryName:
      [
        { text: 'Albania', value: 'AL' },
        { text: 'Åland Islands', value: 'AX' },
        { text: 'Algeria', value: 'DZ' },
        { text: 'American Samoa', value: 'AS' },
        { text: 'Andorra', value: 'AD' },
        { text: 'Angola', value: 'AO' },
        { text: 'Anguilla', value: 'AI' },
        { text: 'Antarctica', value: 'AQ' },
        { text: 'Antigua and Barbuda', value: 'AG' },
        { text: 'Argentina', value: 'AR' },
        { text: 'Armenia', value: 'AM' },
        { text: 'Aruba', value: 'AW' },
        { text: 'Australia', value: 'AU' },
        { text: 'Austria', value: 'AT' },
        { text: 'Azerbaijan', value: 'AZ' },
        { text: 'Bahamas (the)', value: 'BS' },
        { text: 'Bahrain', value: 'BH' },
        { text: 'Bangladesh', value: 'BD' },
        { text: 'Barbados', value: 'BB' },
        { text: 'Belarus', value: 'BY' },
        { text: 'Belgium', value: 'BE' },
        { text: 'Belize', value: 'BZ' },
        { text: 'Benin', value: 'BJ' },
        { text: 'Bermuda', value: 'BM' },
        { text: 'Bhutan', value: 'BT' },
        { text: 'Bolivia (Plurinational State of)', value: 'BO' },
        { text: 'Bonaire, Sint Eustatius and Saba', value: 'BQ' },
        { text: 'Bosnia and Herzegovina', value: 'BA' },
        { text: 'Botswana', value: 'BW' },
        { text: 'Bouvet Island', value: 'BV' },
        { text: 'Brazil', value: 'BR' },
        { text: 'British Indian Ocean Territory (the)', value: 'IO' },
        { text: 'Brunei Darussalam', value: 'BN' },
        { text: 'Bulgaria', value: 'BG' },
        { text: 'Burkina Faso', value: 'BF' },
        { text: 'Burundi', value: 'BI' },
        { text: 'Cabo Verde', value: 'CV' },
        { text: 'Cambodia', value: 'KH' },
        { text: 'Cameroon', value: 'CM' },
        { text: 'Canada', value: 'CA' },
        { text: 'Cayman Islands (the)', value: 'KY' },
        { text: 'Central African Republic (the)', value: 'CF' },
        { text: 'Chad', value: 'TD' },
        { text: 'Chile', value: 'CL' },
        { text: 'China', value: 'CN' },
        { text: 'Christmas Island', value: 'CX' },
        { text: 'Cocos (Keeling) Islands (the)', value: 'CC' },
        { text: 'Colombia', value: 'CO' },
        { text: 'Comoros (the)', value: 'KM' },
        { text: 'Congo (the Democratic Republic of the)', value: 'CD' },
        { text: 'Congo (the)', value: 'CG' },
        { text: 'Cook Islands (the)', value: 'CK' },
        { text: 'Costa Rica', value: 'CR' },
        { text: 'Croatia', value: 'HR' },
        { text: 'Cuba', value: 'CU' },
        { text: 'Curaçao', value: 'CW' },
        { text: 'Cyprus', value: 'CY' },
        { text: 'Czechia', value: 'CZ' },
        { text: 'Côte d\'Ivoire', value: 'CI' },
        { text: 'Denmark', value: 'DK' },
        { text: 'Djibouti', value: 'DJ' },
        { text: 'Dominica', value: 'DM' },
        { text: 'Dominican Republic (the)', value: 'DO' },
        { text: 'Ecuador', value: 'EC' },
        { text: 'Egypt', value: 'EG' },
        { text: 'El Salvador', value: 'SV' },
        { text: 'Equatorial Guinea', value: 'GQ' },
        { text: 'Eritrea', value: 'ER' },
        { text: 'Estonia', value: 'EE' },
        { text: 'Eswatini', value: 'SZ' },
        { text: 'Ethiopia', value: 'ET' },
        { text: 'Falkland Islands (the) [Malvinas]', value: 'FK' },
        { text: 'Faroe Islands (the)', value: 'FO' },
        { text: 'Fiji', value: 'FJ' },
        { text: 'Finland', value: 'FI' },
        { text: 'France', value: 'FR' },
        { text: 'French Guiana', value: 'GF' },
        { text: 'French Polynesia', value: 'PF' },
        { text: 'French Southern Territories (the)', value: 'TF' },
        { text: 'Gabon', value: 'GA' },
        { text: 'Gambia (the)', value: 'GM' },
        { text: 'Georgia', value: 'GE' },
        { text: 'Germany', value: 'DE' },
        { text: 'Ghana', value: 'GH' },
        { text: 'Gibraltar', value: 'GI' },
        { text: 'Greece', value: 'GR' },
        { text: 'Greenland', value: 'GL' },
        { text: 'Grenada', value: 'GD' },
        { text: 'Guadeloupe', value: 'GP' },
        { text: 'Guam', value: 'GU' },
        { text: 'Guatemala', value: 'GT' },
        { text: 'Guernsey', value: 'GG' },
        { text: 'Guinea', value: 'GN' },
        { text: 'Guinea-Bissau', value: 'GW' },
        { text: 'Guyana', value: 'GY' },
        { text: 'Haiti', value: 'HT' },
        { text: 'Heard Island and McDonald Islands', value: 'HM' },
        { text: 'Holy See (the)', value: 'VA' },
        { text: 'Honduras', value: 'HN' },
        { text: 'Hong Kong', value: 'HK' },
        { text: 'Hungary', value: 'HU' },
        { text: 'Iceland', value: 'IS' },
        { text: 'India', value: 'IN' },
        { text: 'Indonesia', value: 'ID' },
        { text: 'Iran (Islamic Republic of)', value: 'IR' },
        { text: 'Iraq', value: 'IQ' },
        { text: 'Ireland', value: 'IE' },
        { text: 'Isle of Man', value: 'IM' },
        { text: 'Israel', value: 'IL' },
        { text: 'Italy', value: 'IT' },
        { text: 'Jamaica', value: 'JM' },
        { text: 'Japan', value: 'JP' },
        { text: 'Jersey', value: 'JE' },
        { text: 'Jordan', value: 'JO' },
        { text: 'Kazakhstan', value: 'KZ' },
        { text: 'Kenya', value: 'KE' },
        { text: 'Kiribati', value: 'KI' },
        { text: 'Korea (the Democratic People\'s Republic of)', value: 'KP' },
        { text: 'Korea (the Republic of)', value: 'KR' },
        { text: 'Kuwait', value: 'KW' },
        { text: 'Kyrgyzstan', value: 'KG' },
        { text: 'Lao People\'s Democratic Republic (the)', value: 'LA' },
        { text: 'Latvia', value: 'LV' },
        { text: 'Lebanon', value: 'LB' },
        { text: 'Lesotho', value: 'LS' },
        { text: 'Liberia', value: 'LR' },
        { text: 'Libya', value: 'LY' },
        { text: 'Liechtenstein', value: 'LI' },
        { text: 'Lithuania', value: 'LT' },
        { text: 'Luxembourg', value: 'LU' },
        { text: 'Macao', value: 'MO' },
        { text: 'Madagascar', value: 'MG' },
        { text: 'Malawi', value: 'MW' },
        { text: 'Malaysia', value: 'MY' },
        { text: 'Maldives', value: 'MV' },
        { text: 'Mali', value: 'ML' },
        { text: 'Malta', value: 'MT' },
        { text: 'Marshall Islands (the)', value: 'MH' },
        { text: 'Martinique', value: 'MQ' },
        { text: 'Mauritania', value: 'MR' },
        { text: 'Mauritius', value: 'MU' },
        { text: 'Mayotte', value: 'YT' },
        { text: 'Mexico', value: 'MX' },
        { text: 'Micronesia (Federated States of)', value: 'FM' },
        { text: 'Moldova (the Republic of)', value: 'MD' },
        { text: 'Monaco', value: 'MC' },
        { text: 'Mongolia', value: 'MN' },
        { text: 'Montenegro', value: 'ME' },
        { text: 'Montserrat', value: 'MS' },
        { text: 'Morocco', value: 'MA' },
        { text: 'Mozambique', value: 'MZ' },
        { text: 'Myanmar', value: 'MM' },
        { text: 'Namibia', value: 'NA' },
        { text: 'Nauru', value: 'NR' },
        { text: 'Nepal', value: 'NP' },
        { text: 'Netherlands (the)', value: 'NL' },
        { text: 'New Caledonia', value: 'NC' },
        { text: 'New Zealand', value: 'NZ' },
        { text: 'Nicaragua', value: 'NI' },
        { text: 'Niger (the)', value: 'NE' },
        { text: 'Nigeria', value: 'NG' },
        { text: 'Niue', value: 'NU' },
        { text: 'Norfolk Island', value: 'NF' },
        { text: 'Northern Mariana Islands (the)', value: 'MP' },
        { text: 'Norway', value: 'NO' },
        { text: 'Oman', value: 'OM' },
        { text: 'Pakistan', value: 'PK' },
        { text: 'Palau', value: 'PW' },
        { text: 'Palestine, State of', value: 'PS' },
        { text: 'Panama', value: 'PA' },
        { text: 'Papua New Guinea', value: 'PG' },
        { text: 'Paraguay', value: 'PY' },
        { text: 'Peru', value: 'PE' },
        { text: 'Philippines (the)', value: 'PH' },
        { text: 'Pitcairn', value: 'PN' },
        { text: 'Poland', value: 'PL' },
        { text: 'Portugal', value: 'PT' },
        { text: 'Puerto Rico', value: 'PR' },
        { text: 'Qatar', value: 'QA' },
        { text: 'Republic of North Macedonia', value: 'MK' },
        { text: 'Romania', value: 'RO' },
        { text: 'Russian Federation (the)', value: 'RU' },
        { text: 'Rwanda', value: 'RW' },
        { text: 'Réunion', value: 'RE' },
        { text: 'Saint Barthélemy', value: 'BL' },
        { text: 'Saint Helena, Ascension and Tristan da Cunha', value: 'SH' },
        { text: 'Saint Kitts and Nevis', value: 'KN' },
        { text: 'Saint Lucia', value: 'LC' },
        { text: 'Saint Martin (French part)', value: 'MF' },
        { text: 'Saint Pierre and Miquelon', value: 'PM' },
        { text: 'Saint Vincent and the Grenadines', value: 'VC' },
        { text: 'Samoa', value: 'WS' },
        { text: 'San Marino', value: 'SM' },
        { text: 'Sao Tome and Principe', value: 'ST' },
        { text: 'Saudi Arabia', value: 'SA' },
        { text: 'Senegal', value: 'SN' },
        { text: 'Serbia', value: 'RS' },
        { text: 'Seychelles', value: 'SC' },
        { text: 'Sierra Leone', value: 'SL' },
        { text: 'Singapore', value: 'SG' },
        { text: 'Sint Maarten (Dutch part)', value: 'SX' },
        { text: 'Slovakia', value: 'SK' },
        { text: 'Slovenia', value: 'SI' },
        { text: 'Solomon Islands', value: 'SB' },
        { text: 'Somalia', value: 'SO' },
        { text: 'South Africa', value: 'ZA' },
        { text: 'South Georgia and the South Sandwich Islands', value: 'GS' },
        { text: 'South Sudan', value: 'SS' },
        { text: 'Spain', value: 'ES' },
        { text: 'Sri Lanka', value: 'LK' },
        { text: 'Sudan (the)', value: 'SD' },
        { text: 'Suriname', value: 'SR' },
        { text: 'Svalbard and Jan Mayen', value: 'SJ' },
        { text: 'Sweden', value: 'SE' },
        { text: 'Switzerland', value: 'CH' },
        { text: 'Syrian Arab Republic', value: 'SY' },
        { text: 'Taiwan (Province of China)', value: 'TW' },
        { text: 'Tajikistan', value: 'TJ' },
        { text: 'Tanzania, United Republic of', value: 'TZ' },
        { text: 'Thailand', value: 'TH' },
        { text: 'Timor-Leste', value: 'TL' },
        { text: 'Togo', value: 'TG' },
        { text: 'Tokelau', value: 'TK' },
        { text: 'Tonga', value: 'TO' },
        { text: 'Trinidad and Tobago', value: 'TT' },
        { text: 'Tunisia', value: 'TN' },
        { text: 'Turkey', value: 'TR' },
        { text: 'Turkmenistan', value: 'TM' },
        { text: 'Turks and Caicos Islands (the)', value: 'TC' },
        { text: 'Tuvalu', value: 'TV' },
        { text: 'Uganda', value: 'UG' },
        { text: 'Ukraine', value: 'UA' },
        { text: 'United Arab Emirates (the)', value: 'AE' },
        { text: 'United Kingdom of Great Britain and Northern Ireland (the)', value: 'GB' },
        { text: 'United States Minor Outlying Islands (the)', value: 'UM' },
        { text: 'United States of America (the)', value: 'US' },
        { text: 'Uruguay', value: 'UY' },
        { text: 'Uzbekistan', value: 'UZ' },
        { text: 'Vanuatu', value: 'VU' },
        { text: 'Venezuela (Bolivarian Republic of)', value: 'VE' },
        { text: 'Viet Nam', value: 'VN' },
        { text: 'Virgin Islands (British)', value: 'VG' },
        { text: 'Virgin Islands (U.S.)', value: 'VI' },
        { text: 'Wallis and Futuna', value: 'WF' },
        { text: 'Western Sahara', value: 'EH' },
        { text: 'Yemen', value: 'YE' },
        { text: 'Zambia', value: 'ZM' },
        { text: 'Zimbabwe', value: 'ZW' },
      ],
    }
  },
  created() {
    this.$http.get('/pricing/data').then(res => { this.pricing = res.data })
  },
  mounted() {
    this.username = JSON.parse(localStorage.getItem('userData')).username
    this.customerId = JSON.parse(localStorage.getItem('userData')).stripeId
  },
  beforeDestroy() {
    this.cardNumber.destroy()
    this.cardExpiry.destroy()
    this.cardCvc.destroy()
  },
  methods: {
    validate() {
      if((this.chose === 0 && this.leviers1.length === 1) || (this.chose === 1 && this.leviers2.length === 2) || (this.chose === 2 && this.leviers3.length === 3) || (this.chose === 3 && this.leviers4.length === 4)) {
        return true
      }
      return false
    },
    limiter1(e) {
      if(e.length > 1) {
        e.pop()
      }
    },
    limiter2(e) {
      if(e.length > 2) {
        e.pop()
      }
    },
    limiter3(e) {
      if(e.length > 3) {
        e.pop()
      }
    },
    limiter4(e) {
      if(e.length > 4) {
        e.pop()
      }
    },
    changeTab() {
      if (this.$refs.form.activeTabIndex === 1) {
        const style = {
          base: {
            iconColor: '#c4f0ff',
            color: '#ccc',
            fontWeight: '500',
            fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
            fontSize: '16px',
            fontSmoothing: 'antialiased',
            ':-webkit-autofill': {
              color: '#fce883',
            },
            '::placeholder': {
              color: '#87BBFD',
            },
          },
          invalid: {
            iconColor: '#FFC7EE',
            color: 'red',
          },
        }

        this.cardNumber = this.stripeElements.create('cardNumber', { style })
        this.cardNumber.mount('#card-number')
        this.cardExpiry = this.stripeElements.create('cardExpiry', { style })
        this.cardExpiry.mount('#card-expiry')
        this.cardCvc = this.stripeElements.create('cardCvc', { style })
        this.cardCvc.mount('#card-cvc')
      }
    },
    async createToken() {
      const { paymentMethod, error } = await this.$stripe.createPaymentMethod({
        type: 'card',
        card: this.cardNumber,
        billing_details: {
          email: this.username,
          name: this.entreprise,
          address: {
            city: this.ville,
            country: this.selectedCountry.value,
            line1: this.adresse,
            postal_code: this.postalcode,
          },
        },
      })
      if (error) {
        // handle error here
        document.getElementById('card-error').innerHTML = error.message
      } else {
        this.createSubscription({
          customerId: this.customerId,
          paymentMethodId: paymentMethod.id,
          priceId: this.lineItems[0].price,
        })
      }
    },
    createSubscription({ customerId, paymentMethodId, priceId }) {
      axios.post('https://app2.oscar-black.com/api/create-subscription', {
        line1: this.adresse,
        postalcode: this.postalcode,
        country: this.selectedCountry.value,
        city: this.ville,
        name: this.entreprise,
        customerId: customerId,
        paymentMethodId: paymentMethodId,
        priceId: priceId,
        leviers1: this.leviers1,
        leviers2: this.leviers2,
        leviers3: this.leviers3,
        leviers4: this.leviers4,
        promo: this.promo,
        choix: this.chose,
      })
        .then(response => {
          const result = response.data
          if (typeof result['status'] === 'undefined') {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Erreur',
                icon: 'CoffeeIcon',
                variant: 'danger',
                text: 'Paiement échoué, votre carte a été déclinée.',
              },
            })
            this.show = false
          }
          else {
              if (result['status'] === 'active') {
                window.location.href = 'https://app.oscar-black.com'
              }
              else if (result['status'] === 'incomplete') {
                if (result['latest_invoice']['payment_intent']['status'] === 'requires_action') {
                  if (result['latest_invoice']['payment_intent']['next_action']['type'] === 'use_stripe_sdk') {
                    if (result['latest_invoice']['payment_intent']['next_action']['use_stripe_sdk']['type'] === 'three_d_secure_redirect') {
                      window.open(result['latest_invoice']['payment_intent']['next_action']['use_stripe_sdk']['stripe_js'], '_blank', 'toolbar=0,location=0,menubar=0')
                      setInterval(() => {
                        axios.post('https://app2.oscar-black.com/api/is_invoice_paid', {
                          id: result['latest_invoice']['id']
                        }).then(response => {
                          if (response.data === 'Ok') {
                            window.location.href = 'https://app.oscar-black.com'
                          }
                        })
                      }, 3000);
                    }
                  }
                }
              }
          }
        })
        .catch(error => {
          // An error has happened. Display the failure to the user here.
          // We utilize the HTML element we created.
          this.showCardError(error)
        })
    },
    showCardError() {

    },
    testCodePromo() {
      axios.get(`https://app2.oscar-black.com/api/codepromotester/${this.promo}`)
      .then(response => {
        this.amount_off = response.data.amount_off
        this.percent_off = response.data.percent_off
        this.disable = true
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Succès',
            icon: 'CoffeeIcon',
            variant: 'success',
            text: 'Code promo valide',
          },
        })
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Erreur',
            icon: 'CoffeeIcon',
            variant: 'danger',
            text: 'Code promo non valide',
          },
        })
        this.disable = false
        this.amount_off = 0
        this.percent_off = 0
      })
    },
    picked(val) {
      axios.post('https://app2.oscar-black.com/api/checkout', {
        'nombre_leviers': val+1,
        'leviers1': this.leviers1,
        'leviers2': this.leviers2,
        'leviers3': this.leviers3,
        'leviers4': this.leviers4
      })
      .then(response => {
        window.location.replace(response.data.url, '_self');
      })
      this.chose = val
      if (val === 0) {
        if(this.leviers1.length == 1) {
          this.$refs.form.nextTab()
        }
        else {

        }
        if (this.monthlyPlanShow === true) {
          this.lineItems = [
            {
              price: 'price_1L5nr9E0wlNdvrJghaop1on3', // FB ADS 390 par mois
              quantity: 1,
            },
          ]
        } else {
          this.lineItems = [
            {
              price: 'price_1L5nr9E0wlNdvrJghaop1on3', // 3480 par an
              quantity: 1,
            },
          ]
        }
      } else if (val === 1) {
        if(this.leviers2.length == 2) {
          this.$refs.form.nextTab()
        }
        else {

        }
        if (this.monthlyPlanShow === true) {
          this.lineItems = [
            {
              price: 'price_1L5nrmE0wlNdvrJg0cR5RvFy', // GADS 390 par mois
              quantity: 1,
            },
          ]
        } else {
          this.lineItems = [
            {
              price: 'price_1L5nrmE0wlNdvrJg0cR5RvFy', // GADS 3480 par an
              quantity: 1,
            },
          ]
        }
      } else if (val === 2) {
        if(this.leviers3.length == 3) {
          this.$refs.form.nextTab()
        }
        else {

        }
        if (this.monthlyPlanShow === true) {
          this.lineItems = [
            {
              price: 'price_1L5ns5E0wlNdvrJgG7DYho1R', // Fb + Google : 690 par mois
              quantity: 1,
            },
          ]
        } else {
          this.lineItems = [
            {
              price: 'price_1L5ns5E0wlNdvrJgG7DYho1R', // Fb + Google : 5880 par an
              quantity: 1,
            },
          ]
        }
      } else if (val === 3) {
        if(this.leviers4.length == 4) {
          this.$refs.form.nextTab()
        }
        else {

        }
        if (this.monthlyPlanShow === true) {
          this.lineItems = [
            {
              price: 'price_1L5nsLE0wlNdvrJgVS1nxfs0', // Fb + Google : 690 par mois
              quantity: 1,
            },
          ]
        } else {
          this.lineItems = [
            {
              price: 'price_1L5nsLE0wlNdvrJgVS1nxfs0', // Fb + Google : 5880 par an
              quantity: 1,
            },
          ]
        }
      }
    },
    formSubmitted() {
      this.show = true
      this.createToken()
    },
    tooglePlan() {
      if (this.status === 'monthly') {
        this.monthlyPlanShow = true
      } else {
        this.monthlyPlanShow = false
      }
    },
  },
  watch: {
  },
  computed: {
    stripeElements() {
      return this.$stripe.elements()
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-pricing.scss';
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';

iframe {
  width:100%;
  height:500px;
}
#card-error {
  color: red;
}
.stripe-element-container {
    padding-top: .55rem;
    padding-bottom: .50rem;
}

/* Blue outline on focus */
.StripeElement--focus {
    border-color: #80BDFF;
    outline:0;
    box-shadow: 0 0 0 .2rem rgba(0,123,255,.25);
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}
#card-number.form-control,
#card-cvc.form-control,
#card-exp.form-control {
    display:inline-block;
}
</style>
